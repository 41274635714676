<template>
  <div class="orManagement">
    <el-container>
      <!-- 左侧树结构 -->
      <el-collapse-transition>
        <el-aside v-show="show">
          <div class="aside-title">机构列表</div>
          <el-tree
            :props="defaultProps"
            :load="loadNode"
            lazy
            @node-click="handleNodeClick"
          ></el-tree>
        </el-aside>
      </el-collapse-transition>
      <!-- 右侧内容区 -->
      <el-main>
        <button @click="show = !show">收缩</button>
        <el-card class="box-card father-card" shadow="never">
          <!-- 头部区域 -->
          <el-row class="btns">
            <el-form ref="form" :model="form" inline>
              <!-- 搜索框 -->
              <el-form-item label="机构名称">
                <el-input
                  v-model="form.companyName"
                  placeholder="请输入机构名称"
                  clearable
                ></el-input>
              </el-form-item>
              <!-- 按钮区域 -->
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="search()"
                  >搜索</el-button
                >
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  @click="addDialogFormVisible = true"
                  >添加
                </el-button>
              </el-form-item>
            </el-form>
          </el-row>
          <!-- 表格区域 -->
          <el-row class="tab">
            <el-table
              ref="multipleTable"
              :data="tabData"
              tooltip-effect="dark"
              style="width: 100%"
              highlight-current-row
              stripe
              border
              :header-cell-style="rowClass"
            >
              <el-table-column
                prop="companyName"
                label="机构名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="egalRepName"
                label="机构法人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="legalPhone"
                label="联系方式"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="regisCapital"
                label="注册资本"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="status"
                label="状态"
                align="center"
              >
                <template slot-scope="scope">
                    {{scope.row.status==1?'有效':'无效'}}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" min-width="100px">
                <template slot-scope="scope">
                  <el-button size="mini" type="success" @click="look(scope.row)"
                    >查看详情</el-button
                  >
                  <el-button
                    size="mini"
                    type="primary"
                    icon="el-icon-edit"
                    @click="edit(scope.row)"
                  >
                    修改</el-button
                  >
                  <el-button
                    size="mini"
                    type="danger"
                    icon="el-icon-delete-solid"
                    @click="open(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handlePageNumChange"
              :current-page="paging.PageNo"
              :page-sizes="[10, 15, 20, 50]"
              :page-size="paging.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
            <!-- 机构详情弹出框 -->
            <el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
              <span slot="title" class="dialog-Title">机构详情</span>
              <el-form
                :model="selectForm"
                inline
                ref="selectForm"
                :rules="selectFormRules"
                class="selectForm"
              >
                <!-- 第一行 -->
                <el-row>
                  <el-form-item label="机构名称" prop="companyName">
                    <el-input
                      v-model="selectForm.companyName"
                      autocomplete="off"
                      clearable
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="机构简称">
                    <el-input
                      v-model="selectForm.enterpriseName"
                      autocomplete="off"
                      clearable
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="机构类型" prop="companyNature">
                    <el-select
                      v-model="selectForm.companyNature"
                      placeholder="请选择机构类型"
                      filterable
                      clearable
                      :disabled="isDisabled"
                    >
                      <el-option
                        :label="item.name"
                        :value="item.name"
                        v-for="(item, index) in institutionalType"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
                <!-- 第二行 -->
                <el-row>
                  <el-form-item label="机构法人" prop="egalRepName">
                    <el-input
                      v-model="selectForm.egalRepName"
                      autocomplete="off"
                      clearable
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="法人代表电话">
                    <el-input
                      v-model="selectForm.legalPhone"
                      autocomplete="off"
                      clearable
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="统一社会信用代码" prop="identifier">
                    <el-input
                      v-model="selectForm.identifier"
                      autocomplete="off"
                      clearable
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <!-- 第三行 -->
                <el-row>
                  <el-form-item label="法人代表身份证号">
                    <el-input
                      v-model="selectForm.legalId"
                      autocomplete="off"
                      clearable
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                </el-row>
                 <el-row>
                    <el-form-item label="状态" prop="status">
                        <el-select
                        v-model="selectForm.status"
                        placeholder="请选择状态"
                        filterable
                        :disabled="isDisabled"
                        >
                        <el-option
                            :label="item.label"
                            :value="item.value"
                            v-for="(item, index) in statusList"
                            :key="index"
                        ></el-option>
                        </el-select>
                  </el-form-item>
                </el-row>
                <!-- 第四行 -->
                <el-row>
                  <el-form-item label="注册资本" prop="regisCapital">
                    <el-input
                      v-model="selectForm.regisCapital"
                      autocomplete="off"
                      clearable
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="成立日期" prop="establishmentDate">
                    <el-input
                      v-model="selectForm.establishmentDate"
                      autocomplete="off"
                      clearable
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <!-- 第五行 -->
                <el-row>
                  <el-form-item label="营业开始时间" prop="businessStartTime">
                    <el-input
                      v-model="selectForm.businessStartTime"
                      autocomplete="off"
                      clearable
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="营业结束时间" prop="businessEndTime">
                    <el-input
                      v-model="selectForm.businessEndTime"
                      autocomplete="off"
                      clearable
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <!-- 第六行 -->
                <el-row>
                  <el-form-item label="组织机构代码">
                    <el-input
                      v-model="selectForm.ownerDivisionCode"
                      autocomplete="off"
                      clearable
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <!-- 第七行 -->
                <el-row>
                  <el-form-item label="经营业户行政区" prop="ownerDivision">
                    <el-cascader
                      placeholder="请选择区域"
                      filterable
                      :options="options"
                      :props="props"
                      v-model="selectForm.ownerDivision"
                      :disabled="isDisabled"
                    ></el-cascader>
                  </el-form-item>
                  <el-form-item label="管理范围" prop="manageArea">
                    <el-cascader
                      placeholder="请选择区域"
                      :options="options"
                      filterable
                      :props="propst"
                      v-model="selectForm.manageArea"
                      :disabled="isDisabled"
                    ></el-cascader>
                  </el-form-item>
                </el-row>
                <!-- 第八行 -->
                <el-row>
                  <el-form-item label="经营范围" prop="businessScope">
                    <el-input
                      v-model="selectForm.businessScope"
                      autocomplete="off"
                      clearable
                      type="textarea"
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <!-- 第九行 -->
                <el-row>
                  <el-form-item label="通信地址" prop="contactAddress">
                    <el-input
                      v-model="selectForm.contactAddress"
                      autocomplete="off"
                      clearable
                      type="textarea"
                      :disabled="isDisabled"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <!-- 第十行 -->
                <!-- <el-row>
                  <el-form-item label="附件" v-model="selectForm.file" prop="file">
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                      :on-change="handleChange"
                      :file-list="fileList"
                      :disabled="isDisabled"
                      ref="upload"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file, fileList)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-row> -->
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="sure()">确 认</el-button>
              </div>
            </el-dialog>
            <!-- 添加弹出框 -->
            <el-dialog
              :visible.sync="addDialogFormVisible"
              class="add-dialog"
              @close="addDialogClose()"
            >
              <!-- :rules="addFormRules" -->
              <span slot="title" class="dialog-Title">添加机构</span>
              <el-form
                :model="addForm"
                inline
                ref="addForm"
                class="addForm"
                :rules="addFormRules"
              >
                <!-- 第一行 -->
                <el-row>
                  <el-form-item label="机构名称" prop="companyName">
                    <el-input
                      v-model="addForm.companyName"
                      autocomplete="off"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="机构简称">
                    <el-input
                      v-model="addForm.enterpriseName"
                      autocomplete="off"
                      clearable
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="机构类型" prop="companyNature">
                    <el-select
                      v-model="addForm.companyNature"
                      placeholder="请选择机构类型"
                      filterable
                      clearable
                    >
                      <el-option
                        :label="item.name"
                        :value="item.name"
                        v-for="(item, index) in institutionalType"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
                <!-- 第二行 -->
                <el-row>
                  <el-form-item label="机构法人" prop="egalRepName">
                    <el-input
                      v-model="addForm.egalRepName"
                      autocomplete="off"
                      clearable
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="法人代表电话">
                    <el-input
                      v-model="addForm.legalPhone"
                      autocomplete="off"
                      clearable
                      onkeyup="value=value.replace(/[^\d]/g,'')"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="统一社会信用代码" prop="identifier">
                    <el-input
                      v-model="addForm.identifier"
                      autocomplete="off"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-row>
                <!-- 第三行 -->
                <el-row>
                  <el-form-item label="法人代表身份证号">
                    <el-input
                      v-model="addForm.legalId"
                      autocomplete="off"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                    <el-form-item label="状态" prop="status">
                        <el-select
                        v-model="addForm.status"
                        placeholder="请选择状态"
                        filterable
                        >
                        <el-option
                            :label="item.label"
                            :value="item.value"
                            v-for="(item, index) in statusList"
                            :key="index"
                        ></el-option>
                        </el-select>
                  </el-form-item>
                </el-row>
                <!-- 第四行 -->
                <el-row>
                  <el-form-item label="注册资本" prop="regisCapital">
                    <el-input v-model="addForm.regisCapital" autocomplete="off" clearable>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="成立日期" prop="establishmentDate">
                    <el-date-picker
                      v-model="addForm.establishmentDate"
                      type="date"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>
                </el-row>
                <!-- 第五行 -->
                <el-row>
                  <el-form-item label="营业开始时间" prop="businessStartTime">
                    <el-time-select
                      v-model="addForm.businessStartTime"
                      :picker-options="{ start: '00:00', step: '00:30', end: '24:00' }"
                      placeholder="选择时间"
                    ></el-time-select>
                  </el-form-item>
                  <el-form-item label="营业结束时间" prop="businessEndTime">
                    <el-time-select
                      v-model="addForm.businessEndTime"
                      :picker-options="{ start: '00:00', step: '00:30', end: '24:00' }"
                      placeholder="选择时间"
                    ></el-time-select>
                  </el-form-item>
                </el-row>
                <!-- 第六行 -->
                <el-row>
                  <el-form-item label="组织机构代码">
                    <el-input
                      v-model="addForm.ownerDivisionCode"
                      autocomplete="off"
                      clearable
                    >
                    </el-input>
                  </el-form-item>
                </el-row>
                <!-- 第七行 -->
                <el-row>
                  <el-form-item label="经营业户行政区" prop="ownerDivision">
                    <el-cascader
                      placeholder="请选择区域"
                      filterable
                      :options="options"
                      :props="props"
                      v-model="addForm.ownerDivision"
                    ></el-cascader>
                  </el-form-item>
                  <el-form-item label="管理范围" prop="manageArea">
                    <el-cascader
                      placeholder="请选择区域"
                      :options="options"
                      filterable
                      :props="propst"
                      v-model="addForm.manageArea"
                    ></el-cascader>
                  </el-form-item>
                </el-row>
                <!-- 第八行 -->
                <el-row>
                  <el-form-item label="经营范围" prop="businessScope">
                    <el-input
                      v-model="addForm.businessScope"
                      autocomplete="off"
                      clearable
                      type="textarea"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <!-- 第九行 -->
                <el-row>
                  <el-form-item label="通信地址" prop="contactAddress">
                    <el-input
                      v-model="addForm.contactAddress"
                      autocomplete="off"
                      clearable
                      type="textarea"
                    ></el-input>
                  </el-form-item>
                </el-row>
                <!-- 第十行 -->
                <!-- <el-row>
                  <el-form-item label="附件" v-model="addForm.file">
                    <el-upload
                      action="#"
                      list-type="picture-card"
                      :auto-upload="false"
                      :on-change="handleChange"
                      :file-list="fileList"
                      ref="upload"
                    >
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{ file }">
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file, fileList)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-row> -->
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addSure()">确 认</el-button>
              </div>
            </el-dialog>
            <!-- 附件预览弹出框 -->
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt />
            </el-dialog>
          </el-row>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'orManagement',
  components: {},
  data () {
    const that = this
    return {
      statusList: [{ label: '无效', value: 0 }, { label: '有效', value: 1 }],
      treeData: [], // 树结构数据源
      defaultProps: {
        label: 'name',
        children: [],
        isLeaf: 'leaf'
      }, // 树结构配置对象
      tabData: [], // 表格数据源
      show: true, // 控制侧边栏的显示与隐藏
      form: {
        companyName: ''
      }, // 搜索表单
      total: 0, // 总数据条数
      corpId: '',
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      addForm: {
        companyName: '',
        enterpriseName: '',
        companyNature: '',
        egalRepName: '',
        legalPhone: '',
        identifier: '',
        legalId: '',
        regisCapital: '',
        establishmentDate: '',
        businessStartTime: '',
        businessEndTime: '',
        ownerDivisionCode: '',
        ownerDivision: '',
        manageArea: '',
        businessScope: '',
        contactAddress: '',
        file: '',
        status: 1
      }, // 添加表单
      // is: true,
      addFormRules: {
        companyName: [
          {
            required: true,
            message: '请输入机构名称',
            trigger: 'blur'
          }
        ],
        companyNature: [
          {
            required: true,
            message: '请输入机构类型',
            trigger: 'blur'
          }
        ],
        egalRepName: [
          {
            required: true,
            message: '请输入机构法人',
            trigger: 'blur'
          }
        ],
        businessScope: [
          {
            required: true,
            message: '请输入经营范围',
            trigger: 'blur'
          }
        ],
        regisCapital: [
          {
            required: true,
            message: '请输入注册资本',
            trigger: 'blur'
          }
        ],
        establishmentDate: [
          {
            required: true,
            message: '请输入成立日期',
            trigger: 'blur'
          }
        ],
        businessStartTime: [
          {
            required: true,
            message: '请输入营业开始时间',
            trigger: 'blur'
          }
        ],
        businessEndTime: [
          {
            required: true,
            message: '请输入营业结束时间',
            trigger: 'blur'
          }
        ],
        contactAddress: [
          {
            required: true,
            message: '请输入通信地址',
            trigger: 'blur'
          }
        ],
        identifier: [
          {
            required: true,
            message: '请输入社会信用代码',
            trigger: 'blur'
          }
        ],
        ownerDivision: [
          {
            required: true,
            message: '请选择行政区',
            trigger: 'blur'
          }
        ],
        manageArea: [
          {
            required: true,
            message: '请选择管理范围',
            trigger: 'blur'
          }
        ],
        file: [
          {
            required: true,
            message: '请上传证件图片',
            trigger: 'blur'
          }
        ]
        // manageArea: [
        //   { required: true, message: '请上传管理区域', trigger: 'blur' }
        // ]
      },
      selectFormRules: {
        companyName: [
          {
            required: true,
            message: '请输入机构名称',
            trigger: 'blur'
          }
        ],
        companyNature: [
          {
            required: true,
            message: '请输入机构类型',
            trigger: 'blur'
          }
        ],
        egalRepName: [
          {
            required: true,
            message: '请输入机构法人',
            trigger: 'blur'
          }
        ],
        businessScope: [
          {
            required: true,
            message: '请输入经营范围',
            trigger: 'blur'
          }
        ],
        regisCapital: [
          {
            required: true,
            message: '请输入注册资本',
            trigger: 'blur'
          }
        ],
        establishmentDate: [
          {
            required: true,
            message: '请输入成立日期',
            trigger: 'blur'
          }
        ],
        businessStartTime: [
          {
            required: true,
            message: '请输入营业开始时间',
            trigger: 'blur'
          }
        ],
        businessEndTime: [
          {
            required: true,
            message: '请输入营业结束时间',
            trigger: 'blur'
          }
        ],
        contactAddress: [
          {
            required: true,
            message: '请输入通信地址',
            trigger: 'blur'
          }
        ],
        identifier: [
          {
            required: true,
            message: '请输入社会信用代码',
            trigger: 'blur'
          }
        ],
        ownerDivision: [
          {
            required: true,
            message: '请选择行政区',
            trigger: 'blur'
          }
        ],
        manageArea: [
          {
            required: true,
            message: '请选择管理范围',
            trigger: 'blur'
          }
        ],
        file: [
          {
            required: true,
            message: '请上传证件图片',
            trigger: 'blur'
          }
        ]
        // manageArea: [
        //   { required: true, message: '请上传管理区域', trigger: 'blur' }
        // ]
      },
      // 添加表单校验规则
      institutionalType: [
        {
          name: '国有企业',
          id: 1
        },
        {
          name: '集体企业',
          id: 1
        },
        {
          name: '私营企业',
          id: 1
        },
        {
          name: '个体工商户',
          id: 1
        },
        {
          name: '国有企业',
          id: 1
        },
        {
          name: '合伙企业',
          id: 1
        },
        {
          name: '联营企业',
          id: 1
        },
        {
          name: '股份合作制企业',
          id: 1
        },
        {
          name: '有限责任公司',
          id: 1
        },
        {
          name: '股份有限公司',
          id: 1
        }
      ], // 机构类型
      options: [], // 经营业户行政区
      props: {
        label: 'province',
        value: 'uuid',
        children: 'hasChild'
      },
      propst: {
        multiple: true,
        label: 'province',
        value: 'uuid',
        children: 'hasChild'
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      isDisabled: true, // 控制对话框的编辑
      node: ''
    }
  },
  created () {
    // this.getProvince()
    this.getP()
    this.getorderCompany()
  },
  methods: {
    // 点击左侧树节点触发
    async handleNodeClick (data, node) {
      console.log(data, 'data')
      if (node.level == 3) {
        // console.log(node);
        this.paging.PageNo = 1
        this.node = node
        this.getorderCompany(this.node)
        // this.getSimplecompanyList(this.node)
      }
    },
    loadNode (node, resolve) {
      console.log(resolve, 'resolve')
      // 如果展开第一级节点，从后台加载一级节点列表
      if (node.level == 0) {
        this.getProvince(resolve)
      }
      // 如果展开其他级节点，动态从后台加载下一级节点列表
      if (node.level == 1) {
        this.getCity(node, resolve)
      }
      if (node.level == 2) {
        this.getDistrict(node, resolve)
      }
      if (node.level == 3) {
        this.node = node
        this.paging.PageNo = 1
        this.getorderCompany(node, resolve)
      }
    },
    // 获取省份--调试完成
    async getProvince (resolve) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectProvinceList',
        {}
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取省份失败')
      res.data.forEach((item) => {
        item.name = item.province
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
      })
      console.log(res.data)
      resolve(res.data)
    },
    // 获取市--调试完成
    async getCity (node, resolve) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectCityList',
        {
          province: node.data.uuid
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取市失败')

      if (resolve == null) {
        return res.data
      } else {
        res.data.forEach((item) => {
          item.name = item.cityName
          if (res.data.count == 0) {
            item.hasChild = false
          } else {
            item.hasChild = true
          }
        })

        resolve(res.data)
      }
      //  console.log(res.data);
    },
    // 获取区域--调试完成
    async getDistrict (node, resolve) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectDistrictList',
        {
          cityId: node.data.uuid
        }
      )
      // console.log(res.data);
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取区域失败')

      res.data.forEach((item) => {
        item.name = item.disName
        if (res.data.count == 0) {
          item.hasChild = false
        } else {
          item.hasChild = true
        }
      })
      // console.log(res.data);
      resolve(res.data)
    },
    // 获取省份--调试完成
    async getP () {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectProvinceList',
        {}
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取省份失败')
      res.data.forEach(async (item) => {
        item.hasChild = await this.getCy(item)
        item.hasChild.forEach(async (i) => {
          i.hasChild = await this.getD(i)
        })
      })
      // console.log(res.data);
      this.options = res.data
      // resolve(res.data);
    },
    // 获取市--调试完成
    async getCy (e) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectCityList',
        {
          province: e.uuid
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取市失败')
      return res.data
    },
    // 获取区域--调试完成
    async getD (e) {
      const { data: res } = await this.$http.post(
        '/userManageServer/area/selectDistrictList',
        {
          cityId: e.uuid
        }
      )
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取区域失败')
      return res.data
    },
    // 按地区获取机构--调试完成
    async getorderCompany (node = { data: { uuid: '' } }, resolve) {
      const { data: res } = await this.$http.post(
        '/userManageServer/tSysCompany/selectCompanyByDisArea',
        {
          district: node.data?.uuid || '',
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          companyName: this.form.companyName
        }
      )
      console.log(res)
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取机构失败')
      this.tabData = res.data
      this.total = +res.pageBean.pageDataCount
      if (resolve) {
        resolve([])
      }
    },
    async getSimplecompanyList (node) {
      if (!node) return false
      // console.log(node.data.uuid)
      this.corpId = node.data.uuid
      const { data: res } = await this.$http.post(
        '/userManageServer/tSysCompany/selectCompany',
        {
          district: node.data.uuid,
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo
        }
      )
      if (res.statusCode !== '200') {
        return this.$message.error('获取机构失败')
      }
      //  console.log(res.data);
      this.tabData = res.data
      this.total = +res.pageBean.pageDataCount
    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 点击搜索按钮触发
    async search () {
      this.paging.PageNo = 1
      this.getorderCompany(this.node || { data: { uuid: '' } })

    //   // 判断有输入账号
    //   if (this.form.companyName === '') {
    //     this.getSimplecompanyList(this.node || { data: { uuid: '' } })
    //   } else {
    //     // 调接口，进行查询
    //     this.paging.PageNo = 1
    //     const { data: res } = await this.$http.post(
    //       '/userManageServer/tSysCompany/selectCompany',
    //       {
    //         pageSize: this.paging.pageSize,
    //         pageNo: this.paging.PageNo,
    //         companyName: this.form.companyName
    //       }
    //     )
    //     console.log(res)
    //     // 判断请求是否成功
    //     if (res.statusCode !== '200') return this.$message.error('查询失败')
    //     this.tabData = res.data
    //     this.total = +res.pageBean.pageDataCount
    //   }
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getorderCompany(this.node)
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getorderCompany(this.node)
    },
    // 添加附件时触发
    handleChange (file, fileList) {
      if (fileList.length > 3) {
        this.$message.warning('最多上传三张图片')
        fileList.splice(fileList.length - 1, 1)
      } else {
        console.log(this.fileList)
        this.fileList.push(file)
        this.addForm.file = this.fileList
      }
    },
    // 点击查看详情按钮触发
    look (v) {
      console.log(v)
      this.isDisabled = true
      v.ownerDivision = [v.province, v.city, v.district]
      if (v.manageAreaList && v.manageAreaList.length != 0) {
        v.manageArea = [
          [
            v.manageAreaList[0].province,
            v.manageAreaList[0].city,
            v.manageAreaList[0].district
          ]
        ]
      }

      this.selectForm = v
      this.orderDialogFormVisible = true
    },
    // 点击编辑按钮触发
    edit (v) {
      console.log(v)
      this.isDisabled = false
      v.ownerDivision = [v.province, v.city, v.district]
      if (v.manageAreaList && v.manageAreaList.length != 0) {
        v.manageArea = [
          [
            v.manageAreaList[0].province,
            v.manageAreaList[0].city,
            v.manageAreaList[0].district
          ]
        ]
      }
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm(`是否确认删除：${e.companyName}`, '删除机构', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      })
        .then(async () => {
          // 发请求，删除用户
          try {
            const { data } = await this.$http.post(
              '/userManageServer/tSysCompany/deleteCompany',
              {
                uuid: e.uuid
              }
            )
            // 判断是否删除成功
            if (data.statusCode !== '200') return this.$message.error('删除机构失败')
            // this.delForm = { uuid: '', userName: '' }
            // 成功提示
            this.$message.success('删除机构成功')
            // 刷新视图
            this.getorderCompany(this.node)
          } catch (e) {
            console.log(e)
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 点击详情确定框触发
    async sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) {
        return (this.orderDialogFormVisible = false)
      } else {
        this.$refs.selectForm.validate(async (valid) => {
          if (!valid) return
          let msg = ''
          let msgs = ''
          if (this.selectForm.manageArea && this.selectForm.manageArea.length != 0) {
            for (var i = 0; i < this.selectForm.manageArea[0].length; i++) {
              if (i == 2) {
                msg += this.selectForm.manageArea[0][i]
              } else {
                msg += this.selectForm.manageArea[0][i] + ','
              }
            }
          }

          this.selectForm.manageArea = msg

          for (var a = 0; a < this.selectForm.ownerDivision.length; a++) {
            if (a == 2) {
              msgs += this.selectForm.ownerDivision[a]
            } else {
              msgs += this.selectForm.ownerDivision[a] + ','
            }
          }
          this.selectForm.ownerDivision = msgs
          // 进行编辑操作
          const { data: res } = await this.$http.post(
            '/userManageServer/tSysCompany/updateCompany',
            JSON.parse(JSON.stringify(this.selectForm))
          )
          // 判断请求是否成功
          if (res.statusCode !== '200') return this.$message.error('修改机构信息失败')
          // 成功提示
          this.$message.success('修改机构信息成功')
          // 重新渲染数据
          this.getorderCompany(this.node)
          this.orderDialogFormVisible = false
        })
      }
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.addForm = {}
      this.fileList = []
      this.$refs.addForm.resetFields()
    },
    // 添加机构--确定按钮
    addSure () {
      console.log(this.addForm)
      // 预校验
      this.$refs.addForm.validate(async (valid) => {
        if (!valid) return
        // if (!this.addForm.file || this.addForm.file.length === 0) return this.$message.warning('必须上传图片')
        this.addForm.province = this.addForm.ownerDivision[0]
        this.addForm.city = this.addForm.ownerDivision[1]
        this.addForm.district = this.addForm.ownerDivision[2]
        var formdata = new FormData()
        var area = ''
        for (var i in this.addForm) {
          if (i === 'manageArea') {
            for (var a = 0; a < this.addForm[i].length; a++) {
              for (var b = 0; b < this.addForm[i][a].length; b++) {
                if ((b + 1) % 3 === 0) {
                  area += this.addForm[i][a][b] + ';'
                } else {
                  area += this.addForm[i][a][b] + ','
                }
              }
            }
            formdata.append('manageArea', area)
          } else if (i === 'establishmentDate') {
            const str = JSON.stringify(this.addForm[i])
            if (this.addForm[i].length == undefined) {
              this.addForm[i] = this.getNowFormatDate(this.addForm[i])
            }

            formdata.append(i, this.addForm[i])
          } else {
            formdata.append(i, this.addForm[i])
          }
        }
        console.log(this.addForm, 8888)
        // for (var i in this.addForm.file) {
        //   // formdata.append('file' + (+i + 1), this.addForm.file[i].raw)
        //   formdata.append('file', this.addForm.file)
        // }
        // formdata.append('createBy', sessionStorage.getItem('userId'))
        // if (this.addForm.file.length > 0) {
        //   formdata.append('flagType', 1)
        // } else {
        //   formdata.append('flagType', 0)
        // }
        const config = {
          headers: {
            'Content-Type': 'multipart/fom-data'
          }
        }
        const { data: res } = await this.$http.post(
          '/userManageServer/tSysCompany/addCompany',
          formdata,
          config
        )
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加机构失败')
        // 成功提示
        this.$message.success('添加机构成功')
        // 重新渲染数据
        this.getorderCompany()
        if (this.node !== '') {
          this.getorderCompany(this.node)
        } else {
          location.reload()
        }
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    handleRemove (file) {
      const fileList = this.$refs.upload.uploadFiles
      const index = fileList.findIndex((fileItem) => {
        return fileItem.uid === file.uid
      })
      fileList.splice(index, 1)
      const i = this.fileList.findIndex((fileItem) => {
        return fileItem.uid === file.uid
      })
      this.fileList.splice(i, 1)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 时间转换格式    格式YYYY-MM-DD
    getNowFormatDate (v) {
      var date = v
      var seperator1 = '-'
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate
      return currentdate
    }
  }
}
</script>
<style lang="less" scoped>
.orManagement {
  width: 100%;
  height: 100%;

  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none;
    /* Chrome Safari */
  }

  .el-container {
    width: 100%;
    height: 100%;

    /deep/ .el-aside {
      border: 1px solid #ccc;

      .aside-title {
        position: relative;
        font-size: 18px;
        padding-left: 10px;
        background-color: #e9f0fe;
        margin-bottom: 5px;
      }
    }

    .el-main {
      padding: 0;

      .father-card {
        .btns {
          margin-bottom: 10px;
        }

        .tab {
          /deep/ .el-table {
            margin-bottom: 20px;

            .el-table__row--striped td {
              background-color: #e4eaec !important;
            }

            .current-row > td {
              background-color: #66b1ff !important;
            }
          }

          .el-pagination {
            margin-bottom: 0.25rem;
            margin-right: 0.25rem;
            text-align: right;
          }
        }

        .add-dialog,
        .order-dialog {
          .dialog-Title {
            color: #0097fe;
            font-size: 20px;
            font-weight: 700;
          }

          /deep/ .el-form {
            .subtitle {
              color: #0097fe;
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 20px;
              padding-left: 6px;
              border-left: 5px solid #0097fe;
            }

            .el-row {
              display: flex;

              .el-form-item {
                flex: 1;
                display: flex;

                .el-form-item__content {
                  flex: 1;

                  .el-input {
                    width: 100%;

                    .el-input__inner {
                      width: 100%;
                    }
                  }

                  .el-select {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
